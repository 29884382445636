import React from "react"
import { Link } from "gatsby"
import "../styles/tarjetaPost.scss"

export default function TarjetaPost(props) {
  let datos = props.post
  let key = props.clave + 1

  if (key < 10) {
    key = "0" + String(key)
  }

  function convertirFecha(fecha) {
    return new Date(fecha).toLocaleDateString("es", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
  }

  return (
    <>
      <article className="tarjeta-entrada">
        <div className="col-fecha">
          <span className="numpost">{key}</span>
          <span>{convertirFecha(datos.published_at)}</span>
        </div>
        <div className="col-imagen">
          <img
            src={`${process.env.API_URL}${datos.imagen_destacada.formats.small.url}`}
            alt=""
          />
        </div>
        <div className="cuerpo-tarjeta">
          <div>
            <div className="categorias">
              {datos.categorias.map((value, index) => {
                return <span key={index}>{value.nombre}</span>
              })}
            </div>
            <h2>
              <Link className="titulo" to={`/blog/${datos.slug}/`}>
                {datos.titulo}
              </Link>
            </h2>
          </div>
          <span className="fecha">{convertirFecha(datos.published_at)}</span>
          <p className="extracto">{datos.extracto}</p>
        </div>
        <hr className="separador" />
      </article>
    </>
  )
}
