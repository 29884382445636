import React, { useEffect, useState } from 'react'
import Layout from "../components/layout";
import Seo from "../components/SEO/Seo"
import { graphql } from 'gatsby'
import '../styles/blog.scss'
import TarjetaPost from '../components/tarjetaPost'
import PrimerPost from '../components/primerPost'
import {Link} from "gatsby"
import { 
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronRight,
} from "react-icons/hi"





export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiPost(
      sort: {order: DESC, fields: published_at}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          titulo
          categorias {
            nombre
          }
          published_at
          extracto
          autor {
            Nombre
          }
          slug
          published_at
          imagen_destacada {
            url
            formats {
              small {
                url
              }
            }
          }
        }
      }
    }
  }  
`


// export default function PaginaBlog(props) {
export default function PaginaBlog({ data, pageContext }) {

  const datos = data.allStrapiPost.edges;

  const [espc, setEspc] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setEspc(window.matchMedia('(min-width: 1024px)').matches)
    }
  }, [])

  function renderTarjeta(espc, node, index, current, limit) {
    index = current === 2 ? index += limit : current > 2 ? index += (limit * (current -1)) : index
    if (index === 0 && espc) {
      return <PrimerPost clave={index} post={node} />
    }
    return <TarjetaPost clave={index} post={node} />
  }

  const current = pageContext.currentPage
  const total = pageContext.numPages
  const limit = pageContext.limit
  const isFirst = current === 1
  const isLast = current === total
  const prevPage = current - 1 === 1 ? '' : (current - 1).toString()
  const nextPage = (pageContext.currentPage + 1).toString()
  
  return (
    <Layout>
      {/* <Seo title="Blog" pathname={pageContext.location.pathname} /> */}
      <Seo 
        title="Blog"
        pathname={"/blog/" + current === 1 ? "" : current}
        robots={current>1 ? "noindex, follow" : "index, follow"} 
      />
      <div className="primera-seccion container">
        <div className="contenedor-posts">
          {datos.map(({ node }, index) => renderTarjeta(espc, node, index, current, limit))}
        </div>
        {total > 1 && (
        <nav role="navigation" aria-label="Paginación del blog" className="contenedor-paginacion">
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
          }}
        >
          {current > 2 && (
            <li>
            <Link to={"/blog/"} title="Primera página" aria-label="Ir a página 1" className='link-paginacion'>
              <HiOutlineChevronDoubleLeft/>
            </Link>
            </li>
          )}
          {!isFirst && (
            <li>
            <Link to={prevPage === "" ? "/blog/" : `/blog/${prevPage}/`} rel="prev" title="Anterior" aria-label="Ir a página anterior" className='link-paginacion'>
              <HiOutlineChevronLeft/>
            </Link>
            </li>
          )}
          {Array.from({ length: total }, (_, i) => (
            
            (i + 1) !== current ? (
            <li
              key={`pagination-number${i + 1}`} 
            >

            <Link
            to={i+1 === 1 ? "/blog/" : `/blog/${i === 0 ? '' : i + 1}/`}
            aria-label={`Ir a página ${i + 1}`}
            title={`Página ${i+1}`}
            style={{
              textDecoration: 'none',
              color: i + 1 === current ? '#ffffff' : '',
              background: i + 1 === current ? '#007acc' : '',
            }}
            >
                {i + 1}
              </Link>
            </li>
            ) : (
              <li className='pagina-actual' aria-label="Página actual">{i + 1}</li>
            )
          ))}
          {!isLast && (
            <li>
            <Link to={`/blog/${nextPage}/`} rel="next" title="Siguiente" aria-label='Siguiente'>
              <HiOutlineChevronRight/>
            </Link>
            </li>
          )}
          {current < total -1 && (
            <li>
            <Link to={`/blog/${total}/`} title="Última página del blog" aria-label="Última página del blog">
              <HiOutlineChevronDoubleRight/>
            </Link>
            </li>
          )}
        </ul>
        </nav>
        )}
      </div>
    </Layout>
  )
}



